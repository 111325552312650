<template>
  <modal name="perfilCreate" height="auto" width="900" :scrollable="true" :clickToClose="false">
    <CloseModal :close="close" />
    <div class="modalbox">
      <div class="modalbox-content">
        <CTabs variant="tabs" class="nav-tabs-boxed">
          <CTab :title="`Criar ${descSingular}`">
            <div class="form">
              <div class="xrow">
                <div class="col-2-3">
                  <div class="form-group">
                    <label for="">Descrição</label>
                    <input class="form-control" v-model="item.descricao" />
                    <div class="message">{{ validation.firstError('item.descricao') }}</div>
                  </div>
                </div>
                <div class="col-1-3">
                  <div class="form-group">
                    <label for="">Ativo</label>
                    <select class="form-control" v-model="item.ativo">
                      <option value="1">Sim</option>
                      <option value="0">Não</option>
                    </select>
                    <div class="message">{{ validation.firstError('item.ativo') }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="xrow">
              <div class="col-1-1">
                <div class="form-group form-group-inline">
                  <label>Disponível para os tipos: </label>
                  <label v-for="tipoColaborador in tiposColaborador" class="checkbox">
                    <input type="checkbox" :value="tipoColaborador.id" v-on:change="handleTipoColaborador">
                    {{ tipoColaborador.desc}}
                  </label>
                </div>
              </div>
            </div>
          </CTab>
        </CTabs>
      </div>
    </div>
    <div class="modal-footer">
      <button :class="{ 'button button-success': true, 'button-disabled': validation.hasError() }" v-on:click="handleSubmit">
        Inserir
      </button>
      <button class="button" v-on:click="close">Fechar</button>
    </div>
  </modal>
</template>
<script>
import { Validator } from "simple-vue-validator";
import _findIndex from "lodash/findIndex";
import _remove from "lodash/remove";
import CloseModal from "../../components/CloseModal";

const initial = () => ({
  fk_sistema: "",
  descricao: "",
  ativo: 1,
  perfisTipos: [],
});

export default {
  name: "perfilCreate",
  props: ["tiposColaborador", "create", "close", "descSingular"],
  components: { CloseModal },
  data() {
    return {
      item: initial(),
    };
  },
  validators: {
    "item.descricao": (value) => Validator.value(value).required(),
    "item.ativo": (value) => Validator.value(value).required(),
  },
  methods: {
    handleSubmit() {
      this.$validate().then((success) => {
        if (success) {
          const item = this.item;
          this.item = initial();
          this.create(item);
        }
      });
    },
    handleTipoColaborador(e) {
      const value = e.target.value;
      const index = _findIndex(this.item.perfisTipos, (t) => +t === +value);
      if (index >= 0) {
        _remove(this.item.perfisTipos, (t) => +t === +value);
      } else {
        this.item.perfisTipos.push(+value);
      }
    },
  },
};
</script>
