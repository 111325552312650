<style scoped>
img.spinner {
  width: 20px;
  margin-right: 10px;
}
</style>
<template>
  <modal
    name="perfilEdit"
    height="auto"
    width="900"
    :scrollable="true"
    :clickToClose="false"
  >
    <CloseModal :close="close" />
    <div class="modalbox">
      <div class="modalbox-content">
        <CTabs variant="tabs" class="nav-tabs-boxed">
          <CTab :title="`Editando ${descSingular}`">
            <div class="row">
              <div class="col-9">
                <div class="form-group">
                  <label for="">Descrição</label>
                  <input class="form-control" v-model="item.descricao" />
                  <div class="message">
                    {{ validation.firstError("item.descricao") }}
                  </div>
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label for="">Ativo</label>
                  <select class="form-control" v-model="item.ativo">
                    <option value="1">Sim</option>
                    <option value="0">Não</option>
                  </select>
                  <div class="message">
                    {{ validation.firstError("item.ativo") }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group form-group-inline">
                  <label>Disponível para os tipos: </label>
                  <div>
                    <label
                      v-for="tipoColaborador in tiposColaborador"
                      :key="tipoColaborador.id"
                      class="button checkbox"
                    >
                      <input
                        type="checkbox"
                        name="toggle"
                        :value="tipoColaborador.id"
                        :checked="
                          getTiposColaboradorSelecionados(tipoColaborador.id)
                        "
                        v-on:change="handleTipoColaborador"
                      />
                      <span>&emsp;{{ tipoColaborador.desc }}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-right">
                <button
                  :class="{
                    'button button-success mx-1': true,
                    'button-disabled': validation.hasError(),
                  }"
                  v-on:click="handleSubmit"
                >
                  Alterar
                </button>
                <button class="button" v-on:click="close">Fechar</button>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <h2>Permissões</h2>
                <CTabs variant="tabs" class="nav-tabs-boxed">
                  <CTab
                    v-for="sistema in sistemas"
                    :title="sistema.descricao"
                    :key="sistema.id"
                  >
                    <div class="p-1">
                      <CCard
                        v-for="modulo in sistema.modulos"
                        :key="modulo.id"
                        class="mb-0"
                      >
                        <CListGroup flush>
                          <CListGroupItem
                            class="d-flex justify-content-between align-items-center"
                          >
                            <strong style="width: 70%">{{
                              modulo.descPlural
                            }}</strong>

                            <CButton
                              v-if="
                                modulo.moduloAcoes.length > 0 &&
                                isModuloIncluso(modulo)
                              "
                              color="light"
                              :disabled="
                                modulo.moduloAcoes.length > 0 ? false : true
                              "
                              @click="
                                accordion =
                                  accordion === modulo.id + '' + item.id
                                    ? false
                                    : modulo.id + '' + item.id
                              "
                              size="sm"
                            >
                              Permissões
                              <fa-icon
                                v-if="accordion != modulo.id + '' + item.id"
                                icon="chevron-down"
                              />
                              <fa-icon
                                v-if="accordion == modulo.id + '' + item.id"
                                icon="chevron-up"
                              />
                            </CButton>

                            <img
                              :src="spinner"
                              class="spinner"
                              v-show="isLoadingModulo(modulo)"
                            />
                            <toggle-button
                              @change="handleChangePermissao(modulo)"
                              :value="isModuloIncluso(modulo)"
                              :sync="true"
                              :labels="{
                                checked: 'Permitido',
                                unchecked: 'Negado',
                              }"
                              :color="{
                                checked: '#7DCE94',
                                unchecked: '#FF877B',
                                disabled: '#CCCCCC',
                              }"
                              :width="80"
                            />
                          </CListGroupItem>
                        </CListGroup>
                        <CCollapse
                          :show="accordion === modulo.id + '' + item.id"
                          v-if="modulo.moduloAcoes.length > 0"
                        >
                          <CCardBody>
                            <CListGroup flush>
                              <CListGroupItem
                                class="d-flex justify-content-between align-items-center"
                              >
                                <strong>Permitir todos</strong>
                                <toggle-button
                                  :value="
                                    isModuloAcaoIncluso(
                                      modulo.moduloAcoes[0].id
                                    )
                                  "
                                  @change="
                                    handleChangeAllPermissaoModulo(modulo, item)
                                  "
                                  :sync="true"
                                  :labels="{
                                    checked: 'Permitido',
                                    unchecked: 'Negado',
                                  }"
                                  :color="{
                                    checked: '#4682B4',
                                    unchecked: '#CD853F',
                                    disabled: '#CCCCCC',
                                  }"
                                  :width="80"
                                />
                              </CListGroupItem>

                              <CListGroupItem
                                v-for="modulos_acoes in modulo.moduloAcoes"
                                :key="modulos_acoes.id"
                                class="d-flex justify-content-between align-items-center"
                              >
                                {{ modulos_acoes.descricao_plural }}

                                <toggle-button
                                  @change="
                                    handleChangePermissaoModuloAcoes(
                                      modulos_acoes,
                                      item
                                    )
                                  "
                                  :value="isModuloAcaoIncluso(modulos_acoes.id)"
                                  :sync="true"
                                  :labels="{
                                    checked: 'Permitido',
                                    unchecked: 'Negado',
                                  }"
                                  :color="{
                                    checked: '#4682B4',
                                    unchecked: '#CD853F',
                                    disabled: '#CCCCCC',
                                  }"
                                  :width="80"
                                />
                              </CListGroupItem>
                            </CListGroup>
                          </CCardBody>
                        </CCollapse>
                      </CCard>
                    </div>
                  </CTab>
                </CTabs>
              </div>
            </div>
          </CTab>
        </CTabs>
      </div>
    </div>
  </modal>
</template>
<script>
import { Validator } from "simple-vue-validator";
import { post } from "@/helpers/apiRequest";
import _findIndex from "lodash/findIndex";
import _remove from "lodash/remove";
import spinner from "../../assets/images/spinner.svg";
import CloseModal from "../../components/CloseModal";

export default {
  name: "perfilEdit",
  props: [
    "item",
    "access",
    "sistemas",
    "tiposColaborador",
    "update",
    "close",
    "modulos",
    "addAcesso",
    "remAcesso",
    "descSingular",
    "addAcessoModuloAcoes",
    "remAcessoModuloAcoes",
  ],
  components: { CloseModal },
  data() {
    return {
      loadingModulos: [],
      spinner,
      accordion: 0,
    };
  },
  computed: {
    pivots() {
      if (this.access && this.access.perfisModulos) {
        return this.access.perfisModulos.reduce((acc, pivot) => {
          acc.push(pivot.modulo_id);
          return acc;
        }, []);
      }
      return [];
    },
  },
  validators: {
    "item.descricao": (value) => Validator.value(value).required(),
    "item.ativo": (value) => Validator.value(value).required(),
  },
  methods: {
    handleChangeAllPermissaoModulo(modulo, perfil) {
      modulo.moduloAcoes.forEach((item) => {
        this.handleChangePermissaoModuloAcoes(item, perfil);
      });
    },

    isModuloIncluso(modulo) {
      return this.pivots.indexOf(+modulo.id) >= 0;
    },

    isLoadingModulo(modulo) {
      return this.loadingModulos.indexOf(modulo.id) >= 0;
    },

    isModuloAcaoIncluso(moduloAcaoId) {
      if (!this.access.perfisModulosAcoes) {
        return false;
      }
      var resultado = this.access.perfisModulosAcoes.find(
        (moduloAcao) => moduloAcao.modulo_acao_id == moduloAcaoId
      );
      if (resultado) {
        return true;
      }
      return false;
    },

    removeLoadingModulo(modulo) {
      let idx = this.loadingModulos.indexOf(modulo.id);
      if (idx >= 0) {
        this.loadingModulos.splice(idx, 1);
      }
    },

    handleChangePermissao(modulo) {
      this.loadingModulos.push(modulo.id);
      if (this.accordion == modulo.id + "" + this.item.id) {
        this.accordion = null;
      }

      /// Se o módulo não está incluso, adiciona ele
      if (!this.isModuloIncluso(modulo)) {
        this.addAcesso(this.item, this.item.id, modulo.id).then(() =>
          this.removeLoadingModulo(modulo)
        );
        return;
      }

      // caso contrário, remove
      this.remAcesso(this.item, this.item.id, modulo.id).then(() =>
        this.removeLoadingModulo(modulo)
      );
    },
    handleChangePermissaoModuloAcoes(ModuloAcao, perfil) {
      if (this.isModuloAcaoIncluso(ModuloAcao.id)) {
        this.remAcessoModuloAcoes(
          perfil,
          perfil.id,
          ModuloAcao.fk_modulo,
          ModuloAcao.id
        );
      }
      if (!this.isModuloAcaoIncluso(ModuloAcao.id)) {
        this.addAcessoModuloAcoes(
          perfil,
          perfil.id,
          ModuloAcao.fk_modulo,
          ModuloAcao.id
        );
      }
    },
    getTiposColaboradorSelecionados(idTipoColaborador) {
      const index = _findIndex(
        this.item.perfisTipos,
        (t) => +t === +idTipoColaborador
      );
      return index >= 0;
    },

    handleTipoColaborador(e) {
      const value = e.target.value;
      const index = _findIndex(this.item.perfisTipos, (t) => +t === +value);
      if (index >= 0) {
        _remove(this.item.perfisTipos, (t) => +t === +value);
      } else {
        this.item.perfisTipos.push(+value);
      }
    },

    handleSubmit() {
      this.$validate().then((success) => {
        if (success) {
          this.update();
        }
      });
    },
  },
};
</script>
